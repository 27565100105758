.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.camera-transform {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.binah-load-animated-img {
  animation: 70s linear 0s infinite normal none running eciKQW;
}

@keyframes slide {
  50% {
    transform: translateX(-100%);
  }

  50.01% {
    transform: translateX(100%);
  }
}

.animate-slide {
  animation: slide 6s linear infinite;
}

.select-none {
  user-select: none;
}

.yellow-color-range-pulseRate {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(230, 236, 104) 0%, rgb(230, 236, 104) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.yellow-color-range-pulseRate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(230, 236, 104);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.green-color-range-pulseRate {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-pulseRate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.red-color-range-pulseRate {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 143, 153) 0%, rgb(255, 143, 153) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.red-color-range-pulseRate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(255, 143, 153);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.yellow-color-range-respirationRate {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(230, 236, 104) 0%, rgb(230, 236, 104) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.yellow-color-range-respirationbreathingRateRate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(230, 236, 104);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.green-color-range-respirationRate {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-respirationRate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.red-color-range-respirationRate {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 143, 153) 0%, rgb(255, 143, 153) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.red-color-range-respirationRate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(255, 143, 153);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.orange-color-range-respirationRate {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 185, 82) 0%, rgb(255, 185, 82) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.orange-color-range-respirationRate::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(255, 185, 82);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.orange-color-range-prq {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 185, 82) 0%, rgb(255, 185, 82) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.orange-color-range-prq::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(255, 185, 82);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.green-color-range-prq {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-prq::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.red-color-range-prq {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 143, 153) 0%, rgb(255, 143, 153) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.red-color-range-prq::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(255, 143, 153);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}
.yellow-color-range-bloodPressure {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(230, 236, 104) 0%, rgb(230, 236, 104) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.yellow-color-range-bloodPressure::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(230, 236, 104);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  z-index: 1;
}

.green-color-range-bloodPressure {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-bloodPressure::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.red-color-range-bloodPressure {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 143, 153) 0%, rgb(255, 143, 153) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.red-color-range-bloodPressure::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(255, 143, 153);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.orange-color-range-pnsIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 185, 82) 0%, rgb(255, 185, 82) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.orange-color-range-pnsIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(255, 185, 82);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.yellow-color-range-pnsIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(230, 236, 104) 0%, rgb(230, 236, 104) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.yellow-color-range-pnsIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(230, 236, 104);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.green-color-range-pnsIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-pnsIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.orange-color-range-rmssd {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 185, 82) 0%, rgb(255, 185, 82) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.orange-color-range-rmssd::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(255, 185, 82);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.yellow-color-range-rmssd {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(230, 236, 104) 0%, rgb(230, 236, 104) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.yellow-color-range-rmssd::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(230, 236, 104);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.green-color-range-rmssd {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-rmssd::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}
.orange-color-range-meanRri {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 185, 82) 0%, rgb(255, 185, 82) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.orange-color-range-meanRri::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(255, 185, 82);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.yellow-color-range-meanRri {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(230, 236, 104) 0%, rgb(230, 236, 104) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.yellow-color-range-meanRri::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(230, 236, 104);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.green-color-range-meanRri {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-meanRri::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.green-color-range-snsIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-snsIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.yellow-color-range-snsIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(230, 236, 104) 0%, rgb(230, 236, 104) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.yellow-color-range-snsIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(230, 236, 104);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.orange-color-range-snsIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(255, 185, 82) 0%, rgb(255, 185, 82) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.orange-color-range-snsIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(255, 185, 82);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.yellow-color-range-sdnn {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(230, 236, 104) 0%, rgb(230, 236, 104) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.yellow-color-range-sdnn::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(230, 236, 104);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.green-color-range-sdnn {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-sdnn::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
}

.input-range {
  -webkit-box-align: center;
  align-items: center;
  height: 26px;
  padding: 0px 12px 0px;
  box-shadow:
    rgb(255, 255, 255) -4px -4px 10px,
    rgb(255, 255, 255) -7px -7px 13px,
    rgba(0, 0, 0, 0.1) 4px 4px 10px inset;
  border-radius: 111px;
  margin-bottom: 20px;
  flex-direction: row;
}

.font-size-range {
  top: 36px;
  font-size: 12px;
  color: rgb(121, 134, 165);
}

.height-90vh {
  height: 90vh;
}

.height-10vh {
  height: 10vh;
}

/* .circular-slider {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 200px;
  height: 200px;
  border: 7px solid red;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.circular-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid black;
} */

/* new */

.circular-slider {
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  width: 200px;
  height: 200px;
  border: 7px solid red;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  /* transform: rotate(-90deg); */
}

.circular-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid black;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(calc(-90deg + (1turn * 0 / 100))); */
}

/* linearGradient strok */

.CircularProgressbar .CircularProgressbar-path {
  stroke: linear-gradient(to right, #ff7e5f, #feb47b) !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.linear-gradient {
  /* Define your linear gradient here */
  /* Example gradient */
  /* You can customize this according to your needs */
  stroke: linear-gradient(to right, #ff7e5f, #feb47b);
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

/* "wellnessIndex" */

.yellow-color-range-wellnessIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, #ffeb3b 0%, #ffeb3b 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.yellow-color-range-wellnessIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: rgb(230, 236, 104);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

/* orange Color */

.orange-color-range-wellnessIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, rgb(207, 119, 10) 0%, rgb(230, 115, 21) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.orange-color-range-wellnessIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: rgb(207, 119, 10);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.green-color-range-wellnessIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, rgb(64, 218, 98) 0%, rgb(64, 218, 98) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.green-color-range-wellnessIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: rgb(64, 218, 98);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.red-color-range-wellnessIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, rgb(255, 143, 153) 0%, rgb(255, 143, 153) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.red-color-range-wellnessIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: rgb(255, 143, 153);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

.dark-red-color-range-wellnessIndex {
  -webkit-appearance: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, rgb(237, 27, 44) 0%, rgb(237, 27, 44) 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.dark-red-color-range-wellnessIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: rgb(237, 27, 44);
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}

/* dark-green */

.dark-green-color-range-wellnessIndex {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, #388e3c 0%, #388e3c 100%);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dark-green-color-range-wellnessIndex::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #388e3c;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid rgb(244, 245, 250);
  box-shadow:
    rgba(10, 51, 139, 0.15) 7px 7px 13px,
    rgb(255, 255, 255) -7px -7px 13px;
  z-index: 1;
}
