@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.w-custom {
  width: 16rem;
}

body {
  /* background-image: linear-gradient(210.52deg, rgba(197, 119, 186, 0.2) 1.28%, rgba(49, 141, 201, 0.2) 95.42%); */
  background-color: rgba(197, 119, 186, 0.2);
}

.content-page h1 {
  font-size: 18px;
  line-height: 19.6px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.content-page h2 {
  font-size: 16px;
  line-height: 19.6px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.content-page h3 {
  font-size: 14px;
  line-height: 19.6px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.content-page p {
  font-size: 14px;
  line-height: 20.6px;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #333;
}

.content-page ul {
  font-size: 14px;
  line-height: 20.6px;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #333;
  list-style: disc;
  padding-left: 20px;
}

.content-page li {
  font-size: 14px;
  line-height: 20.6px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #333;
}

.hidescrollbar::-webkit-scrollbar {
  display: none;
}

.fade-in {
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.custom-shadow {
  box-shadow: rgba(161, 196, 237, 0.25) 0px 7px 8px;
}

#radio_scroll_x::-webkit-scrollbar {
  width: 0px;
}

.isclosed {
  transform: translateX(-100%);
  transition: 0.6s ease-in-out;
  background-color: #fff0;
}

.allclose {
  transform: translateX(-100%);
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.element-to-animate {
  min-width: 3400px;
  animation: slideAnimation 70s linear 0s infinite normal none running;
}

.barCompleted {
  background-color: #40da62;
  border-radius: 50px;
}

.barcontainer {
  background-color: #ededed;
  border-radius: 50px;
}

.react-calendar {
  border: none !important;
  font-family: 'Inter' !important;
  line-height: 1.25em !important;
  border-radius: 10px !important;
  padding: 10px 5px !important;
}

.react-calendar button {
  font-weight: 700;
}

.react-calendar__tile--active {
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.5s;
}

abbr:where([title]) {
  text-decoration: none;
}

@media (max-width: 600px) {
  .react-calendar {
    width: 380px;
  }
}

@media (max-width: 400px) {
  .react-calendar {
    width: 350px;
  }
}

@media (max-width: 380px) {
  .react-calendar {
    width: 320px;
  }
}

.reactSpeedometer .current-value{
  display: none;
}
.reactSpeedometer .segment-value{
  display: none;

}